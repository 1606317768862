import React, { useState, useEffect } from 'react';
import './App.css';
import musicFile from './assets/music.mp3';
import socialIcon1 from './assets/social1.png';
import socialIcon2 from './assets/social2.png';
import socialIcon3 from './assets/social3.png';
import mainImage from './assets/main-image.png';

import image1 from './assets/images/image1.png';
import image2 from './assets/images/image2.png';
import image3 from './assets/images/image3.png';
import image4 from './assets/images/image4.png';
import image5 from './assets/images/image5.png';
import image6 from './assets/images/image6.png';

const images = [image1, image2, image3, image4, image5, image6];
const floatAnimations = ['float1', 'float2', 'float3', 'float4'];

function App() {
  const [entered, setEntered] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [audio] = useState(new Audio(musicFile));

  const handleClick = () => {
    setAnimate(true);
    audio.play();
    setTimeout(() => {
      setEntered(true);
    }, 2000);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText('FqAmeQ8S5WG94C2bodF7AEugoxWfjxdqWN5GB6Sipump');
    alert('Contract address copied!');
  };

  useEffect(() => {
    if (entered) {
      document.body.classList.add('pixelated');
    }
  }, [entered]);

  const getRandomPosition = () => {
    const top = Math.floor(Math.random() * 80) + '%';
    const left = Math.floor(Math.random() * 80) + '%';
    return { top, left };
  };

  const getRandomAnimation = () => {
    const animation = floatAnimations[Math.floor(Math.random() * floatAnimations.length)];
    return animation;
  };

  return (
    <div className="App">
      {!entered ? (
        <div className={`floating-images ${animate ? 'pixelate-animation' : ''}`}>
          {images.map((image, idx) => (
            <img
              key={idx}
              src={image}
              alt={`floating-${idx}`}
              className={`pixelated ${getRandomAnimation()}`}
              style={{ ...getRandomPosition(), animation: `${getRandomAnimation()} 10s infinite` }}
            />
          ))}
          <button onClick={handleClick}>Enter 144p world</button>
        </div>
      ) : (
        <div className="pixelated-world">
          <img src={mainImage} alt="main" className="pixelated" style={{ width: '50%', marginBottom: '20px' }} />
          <h1>$144p Dog</h1><br/>
          <p>Just a CTO of lowest quality meme on Solana, nothing else</p><br/>
          <p onClick={handleCopy} style={{ cursor: 'pointer' }}>FqAmeQ8S5WG94C2bodF7AEugoxWfjxdqWN5GB6Sipump (Click to copy)</p><br/>
          <div className="social-icons">
            <a href="https://dexscreener.com/solana/FqAmeQ8S5WG94C2bodF7AEugoxWfjxdqWN5GB6Sipump" target="_blank" rel="noreferrer">
              <img src={socialIcon1} alt="social1" className="pixelated" />
            </a>
            <a href="https://t.me/cto144p" target="_blank" rel="noreferrer">
              <img src={socialIcon2} alt="social2" className="pixelated" />
            </a>
            <a href="https://x.com/144pdogonsol" target="_blank" rel="noreferrer">
              <img src={socialIcon3} alt="social3" className="pixelated" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
